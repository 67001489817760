<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <h1 class="title">403</h1>
      <h2>Forbidden Access :(</h2>
      <h4>Ooooups! Looks like you don't have access.</h4>
    </div>
  </div>
</template>
<script>
import { firebase } from "@/firebase";
export default {
  mounted() {
    this.$store.commit("setUser", null);
    firebase.auth().signOut();
    setTimeout(() => this.$router.push({ name: "login" }), 3000);
  },
};
</script>
<style scoped>
.title {
  font-size: 8em;
  color: #fff;
  letter-spacing: 14px;
  font-weight: 700;
}
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
</style>
